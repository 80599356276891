import { useState, useContext } from "react";
import { GlobalContext } from "../../../../../../global/GlobalContext";
import "./OneColorSet_GrafData.scss";
import GrafDataDataForGraf from "./GrafData_DataForGraf/GrafData_DataForGraf";
import languages_name from "../../../../../../data/user-data/language/languages_name";
import route_universal from "../../../../../../routes/global/route_universal";
import BtnShow from "../../../../../elements/btn/BTN_Show/Btn_Show";
import { FaRegFilePdf } from "react-icons/fa";
//
import generatePDF from "../../../../../../utils/PDF/generatePDF";
// utils
import { f_date_to_cz } from "../../../../../../utils/date/f_date_to_cz";
import { f_updateConsultationStatus } from "../../../../../../utils/checkBox/colorSets/f_updateConsultationStatus";
import BtnDelete from "../../../../../elements/btn/Btn_Delete/Btn_Delete";
import { useNavigate, useLocation } from "react-router-dom";
import BtnSwitchOnAnotherTag from "../../../../../elements/btn/Btn_Switch_On_Another_Tag/Btn_Switch_On_Another_Tag";
import ColorSetSwitchOnAnotherTagPopUp from "../../../../../elements/btn/Btn_Switch_On_Another_Tag/ColorSet_Switch_On_Another_Tag_PopUp/ColorSet_Switch_On_Another_Tag_PopUp";
import PopUpMedium from "../../../../../elements/popUp/PopUp_Medium/PopUp_Medium";
import FormFeedBackConsultant from "./Form_FeedBack_Consultant/Form_FeedBack_Consultant";
import EmailForm from "../../../../../elements/email/Email_Form/Email_Form";

const OneColorSet_GrafData = ({
  colorSet,
  name,
  setFetch_result,
  setShow,
  // delte user
  setFetch_result_list,
  fetch_result,
  URLValue, 
  setURLValue, 
  userName, 
  setUserName,
  // - switch to another tag - tag list 
  setFetch_tags,
  tag_ID,
  setFetch_respondents_in_tag,
  // - page colorSet - over tag - switch to another tag
  setFetch_result_ColorSet_list,
}) => {
  const { urlServer, admin_rank, setError } = useContext(GlobalContext);

  // Dom
  const navigate = useNavigate();
  const location = useLocation();

  // - Checkboxs
  // - - Ready for consultation
  const [ready_for_consultation, setReady_for_consultation] = useState(
    colorSet.ready_for_consultation
  );
  // - - Consultatiton
  const [consultation, setConsultation] = useState(
    colorSet.consultation_complete
  );
  const [show_consultationForm, setShow_consultationForm] = useState(false)
  // - - Respondent FeedBack
  const [feedback_from_respondent, setFeedback_from_respondent] = useState(
    colorSet.feedback_from_respondent
  );

  // show user Data
  const [showUserData, setShowUserData] = useState(false);

  // show user Data
  const [showInfluencing, setShowInfluencing] = useState(false);

  // Static Texts for wordsColorSet columns
  const wordsColorSet_staticTexts = [
    {
      title: "Ideály",
      description:
        "Zaměstnanec preferuje hodnoty, které jsou abstraktní představy, které jedinec považuje za dokonalé nebo vysoce žádoucí a které ho motivují k určitému způsobu myšlení, chování a rozhodování. Ideály mohou vycházet z osobních přesvědčení, kulturního a společenského prostředí nebo filozofických a náboženských systémů. Mají hluboký psychologický a sociální význam, protože formují osobní identitu, smysl života a ovlivňují každodenní jednání. Mohou vést k seberealizaci a osobnímu růstu, ale také k frustraci, pokud jsou ideály nedosažitelné.",
    },
    {
      title: "Motivace",
      description:
        "Zaměstnanec preferuje hodnoty, které popisují vnitřní procesy, jež vedou člověka k určitému jednání, rozhodování a dosahování cílů. Motivace odpovídá na otázku, proč jednáme určitým způsobem a co nás k tomu vede. Jde o hnací sílu lidského chování, která je ovlivněna jak vnitřními, tak vnějšími faktory. Motivace je klíčovým faktorem, který určuje směr, intenzitu a trvání lidského chování. Je nezbytná pro osobní i profesní rozvoj a naplnění životních cílů. Rozvoj vnitřní motivace, stejně jako pochopení vnějších faktorů, které ovlivňují naše jednání, může pomoci dosáhnout většího naplnění a spokojenosti v životě.",
    },
    {
      title: "Neutralita",
      description:
        "Zaměstnanec preferuje hodnoty, které se podílejí na každodenním fungování jedince v různých situacích a úkolech. Tento koncept lze chápat jako soubor kognitivních, emočních a behaviorálních procesů, které umožňují člověku zvládat běžné aktivity a reagovat na každodenní výzvy.",
    },
    {
      title: "Stresory",
      description:
        "Zaměstnanec preferuje hodnoty, které vyvolávají stresovou reakci v těle a mysli člověka. V psychologii označují všechny faktory, které přispívají k pocitu stresu, ať už jde o fyzická, psychická nebo emocionální reakce na určité události či podmínky. Stresory jsou nedílnou součástí života každého člověka, ale jejich vliv na jednotlivce se liší v závislosti na subjektivním vnímání a schopnostech zvládání stresu. Pochopení a identifikace stresorů, stejně jako rozvoj vhodných copingových mechanismů, jsou klíčové pro udržení zdravé mysli a těla.",
    },
    {
      title: "Destruktory",
      description:
        "Zaměstnanec preferuje hodnoty, které se vztahují k faktorům, událostem nebo mechanismům, které mohou negativně ovlivnit psychické zdraví jednotlivce, narušit jeho fungování nebo způsobit psychické, emoční nebo behaviorální problémy. Mohou zahrnovat jak vnitřní, tak vnější prvky, které vedou k destruktivnímu chování nebo k duševnímu utrpení. Identifikace a porozumění těmto faktorům mohou pomoci jednotlivcům lépe zvládat stres a zlepšovat své psychické zdraví. Uvědomění si destruktorů a práce na jejich překonání je klíčovým krokem k dosažení psychické pohody a zdravého životního stylu.",
    },
  ];

  console.log(colorSet);

  // const [state_showGrafData, setState_showGrafData] = useState(false);

  // - meanings graf (vertical)
  // const [meanings1, setMeanings1] = useState({});
  // const [meanings2, setMeanings2] = useState([]);
  // const [meanings3, setMeanings3] = useState([]);
  // const [meanings4, setMeanings4] = useState([]);
  // const [meanings6, setMeanings6] = useState([]);
  // const [meanings7, setMeanings7] = useState([]);

  // - static texty graf 5
  const inGroup_consciousness_staticTexty = [
    {
      title: "Všechny kladné typy kritického chování",
      description:
        "Jsou označeny znaménkem + a ve významu to znamená chování, které zahrnuje aktivity a přístupy, které vedou k pozitivním výsledkům, zlepšení situací nebo k osobnímu rozvoji. Tyto typy chování mohou být aplikovány v různých kontextech, jako jsou mezilidské vztahy, práce, vzdělání a osobní život. Efektivní a otevřená komunikace je klíčová pro konstruktivní interakce. Zahrnuje aktivní naslouchání, vyjadřování myšlenek a pocitů bez agresivity a schopnost vyřešit konflikty prostřednictvím dialogu.",
    },
    {
      title: "Všechny záporné typy kritického chování",
      description:
        "Jsou označeny znaménkem - a ve významu to znamená chování, které se mohou objevit v situacích, kde by normálně byla očekávána konstruktivní interakce, mohou mít negativní dopad na mezilidské vztahy, pracovní prostředí a osobní pohodu. V některých případech mohou být považovány za záporné nebo destruktivní, ale v určitých situacích mají pomáhající aspekty. Konstruktivní kritika nebo skepticism může přispět k analýze a zlepšení procesů. Zdravá míra negativismu může vést k hlubšímu prozkoumání problémů a identifikaci nedostatků.",
    },
  ];

  // - meanings graf 8
  // const [topThreeMeanings, setTopThreeMeanings] = useState([]);
  // ------------------------------------

  // Gender
  const genderOptions = ["Muž", "Žena", "Ostatní"];
  let genderText = genderOptions[colorSet.gender];

  // Language
  const languageName = languages_name.find(
    (l) => l.code === colorSet.language
  )?.name;

  // Species
  if (colorSet.species !== null && colorSet.species >= 0) {
    const speciesOptions = ["Mužský", "Ženský"];
    const speciesText = speciesOptions[colorSet.species];
    genderText = `${genderText} - ${speciesText}`;
  }

  // ---------------------------------------------------------------------
  const handleGeneratePDF = () => {
    generatePDF({
      // - graf
      id: colorSet._id,
      // - user
      name,
      date_complete: f_date_to_cz(colorSet.date),
      email: colorSet.email,
      company: colorSet.company.companyName,
      date: f_date_to_cz(colorSet.date),
      tag_name: colorSet.tag.name,
      // - 1
      language: languageName,
      years: colorSet.years,
      age: colorSet.age,
      gender: genderText,
      user_name: colorSet.user_name,
      user_surname: colorSet.user_surname,
      // - 2 country
      country: colorSet.country.countryName,
      region: colorSet.region,
      usaRegion: colorSet?.usaRegion?.usaRegionName || null,
      // - 3
      education: colorSet.education.educationName,
      profession: colorSet.profession.professionName,
      maritalStatus: colorSet.maritalStatus.maritalStatusName,
      personsInTheHousehold: colorSet.personsInTheHousehold,
      // - Color words
      redResponse: colorSet.words_scheme.ideals_Response,
      blueResponse: colorSet.words_scheme.motivation_Response,
      greenResponse: colorSet.words_scheme.neutrality_Response,
      whiteResponse: colorSet.words_scheme.stressors_Response,
      purpleResponse: colorSet.words_scheme.destructors_Response,
      // - graf2 meanings
      // graf meanings
      meanings1: colorSet.graf_data.graf1.textSort,
      meanings2: colorSet.graf_data.graf2.textSort,
      meanings3: colorSet.graf_data.graf3.textSort,
      meanings4: colorSet.graf_data.graf4.textSort,
      meanings5: inGroup_consciousness_staticTexty,
      meanings6: colorSet.graf_data.graf6.textSort,
      meanings7: colorSet.graf_data.graf7.textSort,
      meanings9: colorSet.graf_data.graf9.textSort,
      // - graf8 meanings
      meanings8: colorSet.graf_data.graf8.textSort,
      // topThreeMeanings: colorSet.graf_data.graf8.textSort,
      meaningsColumn: wordsColorSet_staticTexts,
    });
  };

  const checkBox_consultation = async (id, fieldName, currentValue) => {
    try {
      // Připravit nový stav, který bude opakem aktuálního
      const newValue = !currentValue;

      // Odeslání PATCH požadavku pomocí route_universal
      const response = await route_universal(
        "PATCH",
        urlServer,
        "/update_checkBox_consultation",
        "colorSet_ID",
        id,
        fieldName,
        newValue
      );

      // Kontrola odpovědi ze serveru
      if (response.success) {
        console.log("Úspěšně aktualizováno:", response.updatedColorSet);

        // Aktualizace stavu checkboxů na základě odpovědi serveru
        setReady_for_consultation(
          response.updatedColorSet.ready_for_consultation
        );
        setConsultation(response.updatedColorSet.consultation_complete);
        setFeedback_from_respondent(
          response.updatedColorSet.feedback_from_respondent
        );

        // Aktualizace fetch_result
        f_updateConsultationStatus(setFetch_result, id, {
          ready_for_consultation:
            response.updatedColorSet.ready_for_consultation,
          consultation_complete: response.updatedColorSet.consultation_complete,
          feedback_from_respondent:
            response.updatedColorSet.feedback_from_respondent,
        });
      } 

      setError(response.msg)

    } catch (err) {
      setError("Chyba při volání checkBox_consultation:", err.message);
    }
  };

  // delete ColorSet
  const f_colorSet_delete = async (set_show) => {
    try {
      const result = await route_universal(
        "DELETE",
        urlServer,
        "/colorSet_delete",
        "colorSet_ID",
        colorSet._id
      );

      if (result.delete_colorSet) {
        // delete colorSet in list
        setFetch_result((prev) => ({
          ...prev,
          fetch_data: prev.fetch_data.filter(
            (item) => item._id !== colorSet._id
          ),
        }));

        // close Confirmation
        set_show(false);

        // close PopUp ColorSet
        setShow(false);
      }

      // delete USER if !== colorSets
      if (result.delete_user_ID) {
        // on Page /users_data
        if(location.pathname === "/users_data") {
          // only one USER
          if (fetch_result.fetch_data.length === 1) {
            if(URLValue) {
              navigate("/users_data", { replace: true }); // delete user_ID from URL
              setURLValue("") // clear searcher
            } else if (userName) {
              setUserName("") // clear searcher
            }
          }
        }

        // delete USER from list
        setFetch_result_list((prev) => ({
          ...prev,
          fetch_data: prev.fetch_data.filter((item) => item._id !== result.delete_user_ID),
        }))
      }

      setError(result.msg);

    } catch (err) {
      setError(`FrontEnd chyba: ${err.message}. Kontaktujte nás. Váš Novitim.`);
    }
  };

  // influencing
  const influencing_values = [
    colorSet.influencing.influencing_OneToThree,
    colorSet.influencing.influencing_isTwinUnderThreshold,
    colorSet.influencing.influencing_isUnderThreshold,
    colorSet.influencing.influencing_threeThreesomes
  ];

  // Influencing count true
  const influencing_true = influencing_values.filter(value => value === true).length;

  // ---------------------------------------------------------------------
  return (
    <div className="OneColorSet_GrafData">
      {/* show_consultationForm */}
      {
        show_consultationForm && <PopUpMedium setClose={setShow_consultationForm} title="FeedBeck formulář pro konzultanta"> 
          <FormFeedBackConsultant 
            colorSet_ID={colorSet._id} 
            setShow_consultationForm={setShow_consultationForm} 
            setConsultation={setConsultation}
            consultation={consultation}
            setFetch_result={setFetch_result}
          />
        </PopUpMedium>
      }
      {colorSet.language && (
        <>
          <div className="colorSetInfo">
            <h4>{name}</h4>
            <p className="email">{colorSet.email}</p>

            <div className="download_PDF">
              <button className="btn_showGraf" onClick={handleGeneratePDF}>
                Stáhnout PDF
                <FaRegFilePdf />
              </button>
            </div>

            {/* Buttons show Graf + Word info */}
            <div className="buttons_box">
              {/* Check Boxs */}
              <div
                className="CheckBox"
                style={{
                  backgroundColor: ready_for_consultation
                    ? "#a8e6a3"
                    : "#f25e0d",
                  transition: "background-color 0.3s ease",
                  color: ready_for_consultation ? "black" : "white",
                }}
              >
                <label>
                  {ready_for_consultation
                    ? "Připraveno pro konzultaci"
                    : "Nepřipraveno pro konzultaci"}
                  <input
                    type="checkbox"
                    checked={ready_for_consultation}
                    onChange={() =>
                      checkBox_consultation(
                        colorSet._id,
                        "ready_for_consultation",
                        ready_for_consultation
                      )
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              <div
                className="CheckBox"
                style={{
                  backgroundColor:
                    admin_rank === 4
                      ? "#d3d3d3" // Šedá barva, pokud admin_rank je 4
                      : consultation
                      ? "#a8e6a3" // Světle zelená
                      : "#f8a5a5", // Světle červená
                  transition: "background-color 0.3s ease",
                }}
              >
                <label>
                  {consultation ? "Odkonzultováno" : "Neodkonzultováno"}
                  <input
                    type="checkbox"
                    checked={consultation}
                    // onChange={() =>
                    //   checkBox_consultation(
                    //     colorSet._id,
                    //     "consultation_complete",
                    //     consultation
                    //   )
                    // }
                    onChange={(e) => {
                      // Zajistíme, že podmínka bude kontrolovat aktuální změnu checkboxu
                      const newValue = e.target.checked;
                      if (!consultation && newValue) {
                        setShow_consultationForm(true); // Otevře okno pouze při přechodu na `true`
                      } else {
                        checkBox_consultation(
                          colorSet._id,
                          "consultation_complete",
                          consultation
                        )
                      }
                    }}
                    disabled={admin_rank === 4}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              <div
                className="CheckBox"
                style={{
                  backgroundColor:
                    admin_rank === 4
                      ? "#d3d3d3" // Šedá barva, pokud admin_rank je 4
                      : feedback_from_respondent
                      ? "#a8e6a3" // Světle zelená
                      : "#f8a5a5", // Světle červená
                  transition: "background-color 0.3s ease",
                }}
              >
                <label>
                  {feedback_from_respondent
                    ? "ZV od respondenta"
                    : "Bez ZV od respondenta"}
                  <input
                    type="checkbox"
                    checked={feedback_from_respondent}
                    onChange={() =>
                      checkBox_consultation(
                        colorSet._id,
                        "feedback_from_respondent",
                        feedback_from_respondent
                      )
                    }
                    disabled={admin_rank === 4 ? true : false}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              {/* Delete OneColorSet */}
              {
                (admin_rank === 0 || admin_rank === 3) && <div className="btn_delete">
                  <BtnDelete
                    title="Smazat vyplněného respondenta"
                    h1="Smazání vyplněného respondenta"
                    name={name}
                    text="Jste si jistí, že chcete smazat vyplněného respondenta?"
                    btn_yes={f_colorSet_delete}
                  />
                </div>
              }

              {/* ColorSet_Switch_On_Another_Tag_PopUp */}
              <BtnSwitchOnAnotherTag>
                <ColorSetSwitchOnAnotherTagPopUp 
                  name={name} 
                  tag_Name={colorSet.tag.name} 
                  respondent_ID={colorSet._id}
                  // - switch to another tag - colorSet list
                  setFetch_result={setFetch_result}
                  // - switch to another tag - tag list 
                  setFetch_tags={setFetch_tags}
                  tag_ID={tag_ID}
                  setFetch_respondents_in_tag={setFetch_respondents_in_tag} 
                  // - page colorSet - over tag - switch to another tag
                  setFetch_result_ColorSet_list={setFetch_result_ColorSet_list}
                />
              </BtnSwitchOnAnotherTag>

                
            </div>

            {/* ---------------------------------------------------------------------------------------- */}
              {/* User data - toggle show */}
            <BtnShow
              text="uživatelská data"
              value={showUserData}
              set={setShowUserData}
            />

            {
              // showUserData &&
              <div
                className={`userData ${showUserData ? "userData_show" : ""}`}
              >
                <div className="userData_box">
                  {/* Company + Day */}
                  <div className="row">
                    <div className="item">
                      <p className="title_item">Datum dokončení: </p>
                      <p>{f_date_to_cz(colorSet.date)}</p>
                    </div>

                    <div className="item">
                      <p className="title_item">Společnost: </p>
                      <p>{colorSet.company.companyName}</p>
                    </div>

                    <div className="item">
                      <p className="title_item">Jazyk: </p>
                      <p>{languageName}</p>
                    </div>
                  </div>

                  {/* color set - user Data */}
                  {/* Language + age + gender */}
                  <div className="row">
                    <div className="item">
                      <p className="title_item">Rok narození: </p>
                      <p>{colorSet.years}</p>
                    </div>
                    <div className="item">
                      <p className="title_item">Věk: </p>
                      <p>{colorSet.age}</p>
                    </div>
                    <div className="item">
                      <p className="title_item">Pohlaví: </p>
                      <p>{genderText}</p>
                    </div>
                  </div>

                  {/* education + maritalStatus + profession + personsInTheHousehold */}
                  <div className="row">
                    <div className="item">
                      <p className="title_item">Vzdělání: </p>
                      <p>{colorSet.education.educationName}</p>
                    </div>
                    <div className="item">
                      <p className="title_item">Rodinný stav: </p>
                      <p>{colorSet.maritalStatus.maritalStatusName}</p>
                    </div>

                    <div className="item">
                      <p className="title_item">Osob v domácnosti: </p>
                      <p>
                        {colorSet.personsInTheHousehold === 9
                          ? "10+"
                          : colorSet.personsInTheHousehold + 1}
                      </p>
                    </div>
                  </div>

                  {/* Country + region */}
                  <div className="row">
                    <div className="item">
                      <p className="title_item">Země: </p>
                      <p>{colorSet.country.countryName}</p>
                    </div>
                    <div className="item">
                      <p className="title_item">Region: </p>
                      <p>{colorSet.region}</p>
                    </div>
                    <div className="item">
                      {colorSet.usaRegion && (
                        <>
                          <p className="title_item">USA Region: </p>
                          <p>{colorSet.usaRegion.usaRegionName}</p>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Profession */}
                  <div className="row">
                    <div className="item">
                      <p className="title_item">Obor: </p>
                      <p>{colorSet.specialization.specialization_Name}</p>
                    </div>
                    <div className="item">
                      <p className="title_item">Povolání: </p>
                      <p>{colorSet.profession.professionName}</p>
                    </div>
                  </div>

                  {
                    (colorSet?.email_send?.noEmail_send || typeof colorSet?.email_send?.email_form === "number")
                    && <div className="send_from_admin">
                    <p className="send_from_admin_title">Odesláno respondentovi</p>
                    
                    <div className="row tag_job">
                      {/* email form */}
                      <EmailForm noEmail_send={colorSet.email_send.noEmail_send} email_form={colorSet.email_send.email_form} />
                      {/* tag_job */}
                      {
                        colorSet.tag_job.tag_profesion_Name && <div className="item">
                          <p className="title_item">Odeslaná pozice: </p>
                          <p>{colorSet.tag_job.tag_profesion_Name}</p>
                        </div>
                      }
                      {/* tag_job URL */}
                      {
                        colorSet.tag_job.tag_profesion_URL && <div className="item">
                          <p className="title_item">URL odeslané pozice: </p>
                          <p>{colorSet.tag_job.tag_profesion_URL}</p>
                        </div>
                      }
                      
                    </div>
                  </div>
                  }
                  {/* Send email form + tag Job */}
                  
                  
                </div>
              </div>
            }

            {/* ----------------------------------------------- */}
            
            {/* influencing - toggle show */}
            <BtnShow
              text="ovlivnění"
              value={showInfluencing}
              set={setShowInfluencing}
              influencing={influencing_true}
            />

            {
              // show influencing
              <div
                className={`influencing ${showInfluencing ? "influencing_show" : ""}`}
              >
                <div className="influencing_box">
                  <p className={`${colorSet.influencing.influencing_OneToThree ? "influencing_true" : "influencing_false"}`}>Jedna trojice barev nad 31.5%</p>
                  <p className={`${colorSet.influencing.influencing_isTwinUnderThreshold ? "influencing_true" : "influencing_false"}`}>Využití tří trojice barev nad 57,2 %</p>
                  <p className={`${colorSet.influencing.influencing_isUnderThreshold ? "influencing_true" : "influencing_false"}`}>Využití trojice barev pod 37,4 %</p>
                  <p className={`${colorSet.influencing.influencing_threeThreesomes ? "influencing_true" : "influencing_false"}`}>Využití dvojice barev Pod 63,9 %</p>
                </div>
              </div>
            }
          </div>

          {/* ----------------------------------------------- */}

          {/* Graf Data */}
          {
            <GrafDataDataForGraf
              grafData={colorSet.graf_data}
              id={colorSet._id}
              // meanings - graf 1
              // meanings1={meanings1}
              // setMeanings1={setMeanings1}
              // // meanings - graf 2
              // meanings2={meanings2}
              // setMeanings2={setMeanings2}
              // // meanings - graf 3
              // meanings3={meanings3}
              // setMeanings3={setMeanings3}
              // // meanings - graf 4
              // meanings4={meanings4}
              // setMeanings4={setMeanings4}
              // static texts - graf 5
              inGroup_consciousness_staticTexty={
                inGroup_consciousness_staticTexty
              }
              // meanings - graf 6
              // meanings6={meanings6}
              // setMeanings6={setMeanings6}
              //  // meanings - graf 7
              //  meanings7={meanings7}
              //  setMeanings7={setMeanings7}
              // meanings - graf 8
              // topThreeMeanings={topThreeMeanings}
              // setTopThreeMeanings={setTopThreeMeanings}
            />
          }

          {/* ----------------------------------------------------- */}
          {/* word's Color */}
          <h4 className="word_schema">Slovní schéma</h4>
          <div className="words_color_res">
            {colorSet.language && (
              <>
                <div className="Ideály wordsColorSetRes">
                  <h3 className="OneColorSet_GrafData_title">Ideály</h3>
                  {colorSet.words_scheme.ideals_Response.map((item, index) => (
                    <div key={index}>
                      <p>{item[1] ? `${item[0]} - ${item[1]}` : item[0]}</p>
                    </div>
                  ))}
                </div>
                <div className="Motivace wordsColorSetRes">
                  <h3 className="OneColorSet_GrafData_title">Motivace</h3>
                  {colorSet.words_scheme.motivation_Response.map(
                    (item, index) => (
                      <div key={index}>
                        <p>{item[1] ? `${item[0]} - ${item[1]}` : item[0]}</p>
                      </div>
                    )
                  )}
                </div>
                <div className="Neutralita wordsColorSetRes">
                  <h3 className="OneColorSet_GrafData_title">Neutralita</h3>
                  {colorSet.words_scheme.neutrality_Response.map(
                    (item, index) => (
                      <div key={index}>
                        <p>{item[1] ? `${item[0]} - ${item[1]}` : item[0]}</p>
                      </div>
                    )
                  )}
                </div>
                <div className="Stresory wordsColorSetRes">
                  <h3 className="OneColorSet_GrafData_title">Stresory</h3>
                  {colorSet.words_scheme.stressors_Response.map(
                    (item, index) => (
                      <div key={index}>
                        <p>{item[1] ? `${item[0]} - ${item[1]}` : item[0]}</p>
                      </div>
                    )
                  )}
                </div>
                <div className="Destruktory wordsColorSetRes">
                  <h3 className="OneColorSet_GrafData_title">Destruktory</h3>
                  {colorSet.words_scheme.destructors_Response.map(
                    (item, index) => (
                      <div key={index}>
                        <p>{item[1] ? `${item[0]} - ${item[1]}` : item[0]}</p>
                      </div>
                    )
                  )}
                </div>
              </>
            )}
          </div>

          {/* Static texts for WordSet columns */}
          <div className="wordSet_columns">
            {wordsColorSet_staticTexts.map((oneText, index) => {
              const { title, description } = oneText;
              return (
                <div key={index} className={`WordSet_columns_oneText ${title}`}>
                  <p className="oneText_title">{title}</p>
                  <p className="oneText_description">{description}</p>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default OneColorSet_GrafData;
