import { useState } from "react";
import "./UserData_listFromLast_result.scss";
import { f_date_to_cz } from "../../../../../utils/date/f_date_to_cz";
import { f_date_only } from "../../../../../utils/date/f_date_only";
import BtnClose from "../../../../elements/btn/BtnClose/BtnClose";
import f_initials from "../../../../../utils/nameAndEmail/f_initials";
import TagUsers from "../../../UserData/Tags/Tag_Users/Tag_Users";
import { useOneColorSet } from "../../../../../hooks/colorSet/useOneColorSet";
import BtnShowColorSetPopUpColorSet from "./UserData_listFormLast_result_btnShowColorSet/BtnShowColorSet_PopUpColorSet/BtnShowColorSet_PopUpColorSet";
import { useNavigate } from 'react-router-dom';
// import CheckBoxonlyShow from "../../../../elements/checkBox/CheckBox_onlyShow/CheckBox_onlyShow";
import CheckBoxsOnlyShow from "../../../../elements/checkBox/CheckBoxs_onlyShow/CheckBoxs_onlyShow";

const UserData_listFromLast_result = ({ fetch_result, setFetch_result }) => {

  console.log(fetch_result)
  const navigate = useNavigate();
  // show Email
  const [showEmail, setShowEmail] = useState({});
  // tagID
  const [selectedTagID, setSelectedTagID] = useState(null);
  // one Color Set
  const [colorSet_ID, setColorSet_ID] = useState({});

  //------------
  // custom hook

  // colorSet loading
  const [fetch_data, setFetch_data] = useOneColorSet(colorSet_ID.ID);

  //------------

  // USER - ID
  const findUser = (userID) => {
    navigate(`../users_data/${userID}`);
  };

  // Email Show
  const btn_email = (id) => {
    setShowEmail((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Přepnutí zobrazení emailu pro konkrétní ID
    }));
  };

  //------------

  return (
    <div className="UserData_listFromLast_result">
      {/* POP UP TagUSers */}
      {selectedTagID?._id && (
        <TagUsers 
          tagData={selectedTagID} 
          setSelectedTagID={setSelectedTagID}
          // page colorSet - over tag - switch to another tag
          setFetch_result_ColorSet_list={setFetch_result} 
        />
      )}
      {/* one color Set */}
      {
        colorSet_ID.ID && fetch_data?._id && 
        <BtnShowColorSetPopUpColorSet 
          setShow={setColorSet_ID} 
          fetch_data={fetch_data} 
          setFetch_data={setFetch_data} 
          name={colorSet_ID.name} 
          setFetch_result={setFetch_result} 
        />
      }

      {fetch_result.fetch_data && (
        <div className="UserData_listFromLast_result_box">
          <div className="titles">
            <div  className="name_email">
              <p>Jméno</p>
            </div>
            <div className="tag">
              <p>Tag</p>
            </div>
            
            {/* <p>Odkonzultováno</p> */}
            <div className="open_row">
              <p className="company">Společnost</p>
              <p className="word_set">Slovní modul</p>
              <p className="date_create">Datum vytvoření</p>
              <p className="actual_status">Aktuální stav</p>
            </div>
          </div>
          {fetch_result.fetch_data.map((oneColorSet_info) => {
            const {
              _id,
              ready_for_consultation,
              consultation_complete,
              feedback_from_respondent,
              user,
              tag,
              tag_info,
              tag_job,
              company,
              date_create,
              date_complete,
              email,
              admin_create,
              wordSet /*, code */,
            } = oneColorSet_info;
            const { userID, userName } = user;
            const { /*admin_ID, */ admin_Name, /* admin_Color */ } =
              admin_create || {};
            const { /*companyID, */ company_Name } = company;
            const { tag_ID, tag_Name } = tag || {};
            const { color } = tag_info || {};
            const { /*wordSet_ID, */ wordSet_Name } = wordSet || {};

            return (
              <div key={_id} className="oneColorSet_info">
                <div className="name_email">
                  <p className="name" onClick={() => findUser(userID)}>{userName}</p>
                  {/* Tlačítko pro zobrazení emailu */}
                  <button className="email_btn" onClick={() => btn_email(_id)}>E-mail</button>
                  {/* Zobrazení emailu podle stavu pro konkrétní ID */}
                  {showEmail[_id] && (
                    <p className="email">
                      {email} <BtnClose setClose={() => btn_email(_id)} />
                    </p>
                  )}
                </div>
                <div className="tag">
                  <div
                    className="tag_box"
                    style={{ backgroundColor: color }}
                    title={admin_Name}
                    onClick={() =>
                      setSelectedTagID({
                        admin_color: tag_info.color,
                        admin_name: `${tag_info.name} ${tag_info.surname}`,
                        company_Name,
                        date_create,
                        name: tag_Name,
                        _id: tag_ID,
                        tag_job,
                        respondent_ID: _id,
                      })
                    }
                  >
                    <div className="tag_data">
                      <p className="admin_name">{f_initials(admin_Name)}</p>
                      <p className="tag">{tag_Name}</p>
                    </div>
                    {/* {
                      tag_job.job_position && <p className="job_position">{tag_job.job_position}</p>
                    } */}
                    <p className="job_position"
                      style={{ backgroundColor: !tag_job.job_position ? color : "" }}
                    >
                      {tag_job.job_position}
                    </p>
                  </div>
                </div>
                {/* open one color set */}
                <div className="open_row" onClick={() => setColorSet_ID({ID:_id, name: userName})}>
                  <div className="company">
                    <p>{company_Name}</p>
                  </div>
                  <div className="word_set">
                    <p>{wordSet_Name}</p>
                  </div>
                  <div className="date_create">
                    <p title={f_date_to_cz(date_create)}>{f_date_only(date_create)}</p>
                  </div>
                  <div className="actual_status">
                    {
                      (ready_for_consultation || consultation_complete || feedback_from_respondent) ? 
                        <CheckBoxsOnlyShow ready_for_consultation={ready_for_consultation} consultation_complete={consultation_complete} feedback_from_respondent={feedback_from_respondent}  /> :
                        <p title={`Datum dokončení: ${f_date_to_cz(date_complete)}`}>{f_date_only(date_complete)}</p>
                    }
                    
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {}
    </div>
  );
};

export default UserData_listFromLast_result;
