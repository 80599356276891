import { useState } from "react";
// import "../../../completeComponents/UserData_listFrom_last_result/UserData_listFrom_last_result.scss";
import "./Companies_Result.scss";
import f_initials from "../../../../utils/nameAndEmail/f_initials";
import { useOneShow_Global } from "../../../../hooks/useOneShow_Global/useOneShow_Global";
import OneCompany from "./OneCompany/OneCompany";

const CompaniesResult = ({ fetch_result, setFetch_result }) => {
  const [showOneCompany, setShowOneCompany] = useState(false);

  // -----------
  // Custom hook
  // newRespondent
  const { fetch_data_one, setFetch_data_one } = useOneShow_Global(
    "/find_oneCompany",
    "oneCompany_ID",
    showOneCompany?.oneColorSet_info?._id
  );

  console.log(fetch_data_one);
  return (
    <div className="Companies_Result">
      {/* One Company */}
      {fetch_data_one?.fetch_data?._id &&
        showOneCompany?.oneColorSet_info?._id && (
          <OneCompany
            setCompanies_fetch={setFetch_result}
            fetch_data={fetch_data_one?.fetch_data}
            setFetch_data_one={setFetch_data_one}
            admin_create_data={showOneCompany?.oneColorSet_info?.admin}
            setClose={setShowOneCompany}
          />
        )}

      {fetch_result.fetch_data && (
        <div className="Companies_Result_box">
          <div className="titles">
            <p className="admin_name">Zakladatel</p>
            <div className="company">
              <p>Jméno společnosti</p>
            </div>
            <p className="coins">Počet coinů</p>
            <p className="tags_number">Počet tagů</p>
          </div>

          {fetch_result.fetch_data.map((oneColorSet_info) => {
            const { _id, company, admin, coins, tagCount } = oneColorSet_info;
            const { name, surname, color } = admin;

            return (
              <div
                key={_id}
                className="company_row"
                onClick={() => setShowOneCompany({ oneColorSet_info })}
              >
                <div className="admin_name">
                  <div
                    className="admin_box"
                    style={{
                      backgroundColor: color,
                    }}
                  >
                    <p
                      className="admin_n"
                      title={`${admin.name} ${admin.surname}`}
                    >
                      {f_initials(`${name} ${surname}`)}
                    </p>
                  </div>
                </div>
                <div className="company">{company}</div>
                <div className="coins">{coins}</div>
                <div className="tags_number">{tagCount}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CompaniesResult;
