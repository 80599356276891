import "./scss/Login.scss";
import { useState, useEffect } from "react";
// import InputContainer from "../components/elements/inputs/InputContainer/InputContainer";
// import InputPassword from "../components/elements/inputs/InputPassword/InputPassword";
import BtnLogin from "../components/pages/Login/BtnLogin/BtnLogin";
import logo_dmaps from "../img/logo/dmaps/logo_dmaps_orange.png"

import InputLineText from "../components/elements/inputs/InputLine/InputLine_Text/InputLine_Text";
import InputLinePassword from "../components/elements/inputs/InputLine/InputLine_Password/InputLine_Password";


const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [error, setError] = useState("");

  // -------------------------

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      // Najdi tlačítko typu 'submit'
      const btn = document.querySelector("button[type='submit']");
      if (btn) {
        btn.click();
      } 
    } 
  };

  useEffect(() => {
    // Přidání globálního posluchače události při načtení komponenty
    document.addEventListener("keydown", handleKeyPress);

    // Odebrání posluchače při odpojení komponenty
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // -------------------------

  return (
    <div className="Login">
      {/* Rotate logo */}
      <img className="logo_rote" src={logo_dmaps} alt="Logo DMAPS" />
      <form className="form">
        <img className="logo" src={logo_dmaps} alt="Logo DMAPS" />

        <div className="title">
          <h1>Portál</h1>
        </div>
        
        <div className="input_box">
          {/* <InputContainer
            type="email"
            label="E-mail"
            id="email"
            value={loginEmail}
            set={setLoginEmail}
            regexSetting="email"
            autoComplete="email"
          />
          <InputPassword
            label="Heslo"
            id="password"
            value={loginPassword}
            set={setLoginPassword}
            regexSetting="password"
            autoComplete="current-password"
          /> */}

          <InputLineText
            type="email"
            placeholder="E-mail"
            value={loginEmail}
            set={setLoginEmail}
            regexSetting="email"
            autoComplete="email"
          />
          <InputLinePassword 
            placeholder="Heslo"
            value={loginPassword}
            set={setLoginPassword}
            regexSetting="password"
            autoComplete="current-password"
          />
          
        </div>
        <div
          className="error_box"
          style={{ color: error === "Vítejte" ? "green" : "red" }}
        >
          <p>{error}</p>
        </div>
        <div className="button_box">
          <BtnLogin loginEmail={loginEmail} loginPassword={loginPassword} setErrorMSG={setError} />
        </div>
      </form>
    </div>
  );
};

export default Login;
