import { TbMail } from "react-icons/tb";
import { IoLinkSharp } from "react-icons/io5";
import "./Email_Form.scss";

const EmailForm = ({ noEmail_send, email_form }) => {
  return (
    <div className="Email_Form">
      {/* send email */}
      {!noEmail_send && (
        <div className="send" title={`Automaticky odeslaný e-mail formou ${email_form + 1}`} >
          <TbMail className="email_send" />
          <p className="email_form">{email_form + 1}</p>
        </div>
      )}

      {/* noSend email */}
      {noEmail_send && (
        <IoLinkSharp className="noEmail_send" title="Ručně odeslaný e-mail" />
      )}
    </div>
  );
};

export default EmailForm;
