import { useState } from "react";
// import "../../../completeComponents/UserData_listFrom_last_result/UserData_listFrom_last_result.scss";
import "./Tags_response_listFromLast.scss";
import f_initials from "../../../../utils/nameAndEmail/f_initials";
import { f_date_only } from "../../../../utils/date/f_date_only";
import TagUsers from "./Tag_Users/Tag_Users";
import CheckBoxonlyShow from "../../../elements/checkBox/CheckBox_onlyShow/CheckBox_onlyShow";

const TagsResponseListFromLast = ({ fetch_result, setFetch_tags }) => {
  const [selectedTagID, setSelectedTagID] = useState(null);

  // Page Have in bottom POPUP - show Tag USERS

  const btnShow_tagUsers = (tagData) => {
    setSelectedTagID(tagData);
  };

  return (
    // UserData_listFrom_last_result
    <div className="Tags_response_listFromLast">
      {/* POP UP TagUSers */}
      {selectedTagID?._id && (
        <TagUsers
          tagData={selectedTagID}
          setSelectedTagID={setSelectedTagID}
          setFetch_tags={setFetch_tags}
        />
      )}

      {fetch_result.fetch_data && (
        <>
          <div className="titles">
            <div className="Tag_name">
              <p>Tag</p>
            </div>
            
            <p className="company">Společnost</p>
            <p className="word_set">Slovní modul</p>
            <p className="date_create">Datum vytvoření</p>
            <p className="complete_notComplete">Nevyplněné</p>
            <p className="complete_notComplete">Vyplněné</p>
            <p className="private_title">Soukromý</p>
          </div>

          {fetch_result.fetch_data.map((oneTag) => {
            const {
              _id,
              name,
              tag_job,
              date_create,
              company,
              admin_create,
              newRespondent_number,
              colorSet_number,
            } = oneTag;
            const { /*company_ID, */ company_Name } = company;
            const {
              admin_color,
              admin_name /*, admin_email, admin_company_ID, admin_ID  */,
            } = admin_create;

            return (
              <div
                key={_id}
                className="tag_row"
                onClick={() =>
                  btnShow_tagUsers({
                    _id,
                    name,
                    tag_job,
                    admin_color,
                    admin_name,
                    company_Name,
                    date_create,
                    private_chackBox: admin_create.private,
                    lock_chackBox: admin_create.lock,
                  })
                }
              >
                

                <div className="Tag_name">
                  <div
                    className="tag_box"
                    style={{ backgroundColor: admin_color }}
                    title={admin_name}
                  >
                    <div className="tag_data">
                      <p className="admin_name">{f_initials(admin_name)}</p>
                      <p className="tag">{name}</p>
                    </div>
                    <p 
                      className="job_position" 
                      style={{ backgroundColor: !tag_job?.job_position ? admin_color : "" }}
                    >
                      {tag_job?.job_position}
                    </p>
                  </div>
                </div>

                <div className="company">
                  <p>{company_Name}</p>
                </div>

                <div className="word_set">
                  <p>{oneTag.default_wordSet?.wordSet_Name || ""}</p>
                </div>

                <div className="date_create">
                  <p>{f_date_only(date_create)}</p>
                </div>

                <div className="complete_notComplete">
                  <p>{newRespondent_number}</p>
                </div>

                <div className="complete_notComplete">
                  <p>{colorSet_number}</p>
                </div>
                
                {/* Private Tag */}
                <div className="private_title">
                  <CheckBoxonlyShow
                    value={admin_create.lock}
                    title="Zamknuté výstupy"
                  />
                  <CheckBoxonlyShow
                    value={admin_create.private}
                    title="Neviditelný tag"
                  />
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default TagsResponseListFromLast;
