import "./InputLine_Text.scss";
import f_input_regex from "../../../../../utils/elements/regex/f_input_regex";

const InputLineText = ({
  type,
  placeholder,
  value,
  set,
  regexSetting,
  autoComplete,
  readOnly,
}) => {
    const inputChange = (value) => {
        if (f_input_regex(regexSetting, value)) {
          set(value);
        }
    };

  return (
    <div className='InputLine_Text'>
         <input
          className="custom-input"
          type={type}
          onChange={(e) => inputChange(e.target.value)}
          value={value}
          autoComplete={autoComplete}
          readOnly={readOnly}
          placeholder={placeholder}
        />
    </div>
  )
}

export default InputLineText