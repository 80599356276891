import { useState } from "react";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import "./InputLine_Password.scss";
import InputLineText from "../InputLine_Text/InputLine_Text";

const InputLinePassword = ({
  placeholder,
  value,
  set,
  regexSetting,
  autoComplete,
}) => {
  const [type, setType] = useState("password");

  const btnPassword = (e) => {
    e.preventDefault();

    setType((prevState) => (prevState === "password" ? "text" : "password"));
  };

  return (
    <div className="InputLine_Password">
      <InputLineText
        type={type}
        placeholder={placeholder}
        value={value}
        set={set}
        regexSetting={regexSetting}
        autoComplete={autoComplete}
      />
      <button className="password" onClick={btnPassword} type="button">
              {type === "password" ? <ImEye /> : <ImEyeBlocked />}
    </button>
    </div>
  );
};

export default InputLinePassword;
