import React from 'react'
import "./Btn_Show.scss"
import { SlArrowDown } from "react-icons/sl";

const BtnShow = ({
  text, 
  value, 
  set, 
  // for influencing/
  influencing
}) => {

  return (
    <div className="Btn_Show">
        <button className="userData_show" onClick={() => set(!value)} style={{color: influencing >= 4 ? "orange" : "white"}}>{value ? "Skrýt" : "Zobrazit"} {text}<SlArrowDown /> </button>
    </div>
  )
}

export default BtnShow;