import React, { useState, useContext } from "react";
import { GlobalContext } from "../../../../../../../global/GlobalContext";
import "./Form_FeedBack_Consultant.scss";
import route_universal from "../../../../../../../routes/global/route_universal";
import { f_updateConsultationStatus } from "../../../../../../../utils/checkBox/colorSets/f_updateConsultationStatus";
import { FaWpforms } from "react-icons/fa6";
import { LuCopy } from "react-icons/lu";

const FormFeedBackConsultant = ({
  colorSet_ID,
  setShow_consultationForm,
  setConsultation,
  consultation,
  setFetch_result,
}) => {
  const { urlServer, setError } = useContext(GlobalContext);

  const [isHovered_ID, setIsHovered_ID] = useState(false); // Stav pro hover

  const f_copyText = async () => {
    try {
      await navigator.clipboard.writeText(colorSet_ID);
      setError("ID diagnostiky zkopírováno");
    } catch (err) {
      setError("Error fronend: " + err);
    }
  };

  const btn = async () => {
    // setSend_consultationForm(true)
    setShow_consultationForm(false);

    try {
      // Připravit nový stav, který bude opakem aktuálního
      const newValue = !consultation;

      // Odeslání PATCH požadavku pomocí route_universal
      const response = await route_universal(
        "PATCH",
        urlServer,
        "/update_checkBox_consultation",
        "colorSet_ID",
        colorSet_ID,
        "consultation_complete",
        newValue
      );

      // Kontrola odpovědi ze serveru
      if (response.success) {
        console.log("Úspěšně aktualizováno:", response.updatedColorSet);

        // Aktualizace stavu checkboxů na základě odpovědi serveru
        setConsultation(response.updatedColorSet.consultation_complete);

        // Aktualizace fetch_result
        f_updateConsultationStatus(setFetch_result, colorSet_ID, {
          consultation_complete: response.updatedColorSet.consultation_complete,
        });
      }

      setError(response.msg);
    } catch (err) {
      setError("Chyba při volání checkBox_consultation:", err.message);
    }
  };

  return (
    <div className="Form_FeedBack_Consultant">
      <div className="box">
        <p className="number">1.</p>
        <div className="code_box">
          <p className="code_title">ID diagnostiky</p>
          <p
            className="code_data"
            onClick={f_copyText}
            onMouseEnter={() => setIsHovered_ID(true)} // Nastaví hover stav
            onMouseLeave={() => setIsHovered_ID(false)} // Odstraní hover stav
          >
            {colorSet_ID}{" "}
            <LuCopy
              className="copy-icon"
              size={20}
              style={{
                color: isHovered_ID ? "#f25e0d" : "#a8a8a8", // Barva ikony při hoveru
                transition: "color 0.3s ease", // Plynulý přechod barvy
              }}
            />
          </p>
        </div>
        <div></div>
      </div>

      <div className="link_box box">
        <p className="number">2.</p>
        <div className="code_box">
          <p className="code_title">Odkaz na formulář</p>
          <a
            className="link"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeixc9FQIrBUgqULxrKLNjBdmoYpEO5vkdhU8tdVrVM82rEBw/viewform"
            target="_blank"
            rel="noreferrer"
            title="Odkaz na formulář pro feedBack konzultanta"
          >
            <FaWpforms />
          </a>
        </div>
        <div></div>
      </div>

      <div className="btn_box">
        <p className="number">3. </p>
        <button onClick={btn}>Formulář vyplněn</button>
        <div></div>
      </div>
    </div>
  );
};

export default FormFeedBackConsultant;
